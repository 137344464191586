<template>
    <v-dialog max-width="50%" v-model="showContent" :opacity="opacity" @click:outside="closeContent">
        <v-card flat class="overlay-card">
            <v-toolbar color="primary">
                <v-toolbar-title style="color: white;">{{ media.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeContent" dark>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-col>
                <component :is="loadAsyncComponent" :media="media"></component>
            </v-col>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        media: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        absolute: true,
        opacity: 0.9,
        showContent: true
    }),

    computed: {
        loadAsyncComponent() {
            switch (this.media.type) {
                case 0:
                case 1:
                    return () => ({
                        component: import("./AudioVideoPlayer"),
                        //   loading: AwesomeSpinner,
                        //   error: SadFaceComponent,
                        timeout: 1000
                    });

                case 2:
                    return () => ({
                        component: import("./Attachment"),
                        //   loading: AwesomeSpinner,
                        //   error: SadFaceComponent,
                        timeout: 1000
                    });
            }
            return null
        }
    },

    methods: {
        closeContent() {
            this.$emit("close");
        }
    },

    mounted() {
        console.log(this.media);
    }
};
</script>

<style scoped>
/* .v-overlay >>> .v-overlay__content {
    border: 3px dashed cyan;
} */
</style>
